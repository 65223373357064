<template>
    <div class="white pa-4">
        <Block v-if="loading" />

        <v-row style="margin: 1rem 0">
            <v-col cols="12">
                <v-radio-group v-model="dataType">
                    <v-radio label="Ver reporte con datos de prueba" value="00"></v-radio>
                    <v-radio label="Ver reporte con datos de producción" value="01"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        
        <v-row class="mt-2">
            
            <v-col cols="12" md="12" class="d-flex justify-end">
                <v-btn class="btn-add" @click="setupPrint">
                    <v-icon>mdi-printer</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <VersatileFilter :columnsSearch="columsConfig" :search="search" @emptyFilter="getData" 
           :disableDates="true"
                 @filtered="filterHandler"
                        :key="Reloadfilter" :columnfilterEndpoint="$API.inventoryReports.getGeneralResumeColReport"
                       @enabledate="(e) => {useDate = e;}"
                >
            <DxDataGrid 
            :dataSource="DataTable"
            :columns="columnsList"
            @exporting="onExporting"
            :word-wrap-enabled="true"
            :scrolling="{ useNative: true }"    
                    :column-auto-width="true"
            >
                <DxExport :enabled="true" />

                <DxPager :showPageSizeSelector="true" :allowedPageSizes="[10, 30, 50]" />
                <DxPaging :page-size="10" />
            </DxDataGrid>
        </VersatileFilter>

        <alerts
            v-if="alert.show"
            v-on:close_alert="closeAlert"
            v-on:accept_alert="acceptAlert"
            :properties="alert"
        />
        <div>
            <component v-for="(item, index) in PrintList" :key="index" :is="item.component" v-bind="item.props" />
        </div>
    </div>
</template>

<script>
    
    //import {mapState} from 'vuex';
    import VersatileFilter from '@/components/VersatileFilter.vue';
    import { DxDataGrid, DxPager, DxPaging, DxExport } from 'devextreme-vue/data-grid';
    import Block from '@/components/Block.vue';
    import Alerts from '@/components/Alerts.vue';
    import { printToExcel } from "@/helpers/printToexcel";
  
    import PrintStockSummary from "@/components/ReportsAndPrintings/AccountigReports/PrintStockSummary";
    export default {
        name: "GeneralInventory",
        components: {
            DxDataGrid,
            DxPager,
            DxPaging,
            Block,
            Alerts,
            DxExport,
            VersatileFilter,
        },
        props: ['win', 'business','datafilter'],
        data() {
            return {
                dataType: '01',
                DataTable: [],
                panel: 0,
                tab: null,
                key: 0,
                loading: false,
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                startDate: null,
                endDate: null,
                useDate : false,
                SellerList: [],
                seller: null,
                columnsList: [

                { dataField: "Familia", caption: "Familia"},
                { dataField: "Categoria", caption: "Categoria" },
                { dataField: "Subcategoria", caption: "Sub Categoria" },
                { dataField: "LastPurchaseDate", caption: "Ultima Compra " },
                { dataField: "LastSaleDate", caption: "Ultima Venta" },
                { dataField: "AverageCost", caption: "Costo Promedio" },
             
                ],
                columsConfig: [
                {
                        name: "Id_family",
                        title: "Familia",
                        value: '',
                        type: "select",
                        options: [],
                    },
                    {
                        name: "Id_category",
                        parent: 'Id_family',
                        title: "Categoría",
                        value: '',
                        type: "select",
                        options: [],
                    },
                    {
                        name: "Id_subcategory",
                        title: "Subcategoría",
                        parent: 'Id_category',
                        value: '',
                        type: "select",
                        options: [],
                    },
                ],
                Currency: JSON.parse(localStorage.getItem('branch')).Currency || "",
                search: {
                    ID_Seller: null,
                },
                summary: {
                    Total: 0,
                },
                Reloadfilter: 0,
                PrintList: [],
                print: {
                    show: false,
                    header: {
                    },
                    data: [],
                },
            }
        },
        watch: {
            dataType(newVal) {
                this.search.dataType = newVal;
            },
        },
        computed:{
            
        },
        mounted() {
            this.search.dataType = this.dataType;
            this.assignParams();
            this.loadData();
        },
        methods: {
            handleShowData(data){
              this.summary.Total = 0;
              this.DataTable = data.map(item => {
                    this.summary.Total += item.AverageCostRaw;
                  return {
                      ...item,
                      
                  }
              });
            },
            loadData(){
                    this.getData();
                
            },
            assignParams(){
                if(this.datafilter){
                    this.columsConfig[0].options = this.datafilter.Families;
                    this.columsConfig[1].options = this.datafilter.Categories;
                    this.columsConfig[2].options = this.datafilter.SubCategories;
                    this.Reloadfilter += 1;
                }
            },
            setupPrint(){
                this.print.data.body = this.DataTable;
                this.print.header.title = "Reporte de Inventario General";
               
                const date = new Date();
                
                this.print.header.summary = this.summary;
                this.print.header.business = this.business;
                //date wiht format dd/mm/yyyy
                this.print.header.cutDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

                this.print.show = true;
                 this.PrintList.push({component: PrintStockSummary, props: {
                    dataProps: this.print,
                }}); 
            },
            onExporting(e) {
              printToExcel(e, "Reporte de De Inventario General");
            },
            filterHandler(data){
            
                if(data.length > 0){
                    this.handleShowData(data);
                    return;
                }
                else{
                    this.DataTable = [];
                }
                
            },
            async getData(){
                this.loading = true;
                
                this.Reloadfilter++;
               
                try {
                    let response = await this.$API.inventoryReports.getGeneralResume({dataType: this.dataType});
                    if(response.length > 0){
                        this.handleShowData(response);
                    }
                    else{
                        this.DataTable = [];
                    }
                    
                    this.loading = false;    
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                    this.showAlert('danger', 'Error', "Error al obtener los datos");
                }
                this.loading = false;
            },
            closeAlert() {
                this.alert.show = false; 
            },
            acceptAlert() {
                this.alert.show = false;
            },
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },
            changueStr(date){
                this.startDate = date;
            },
            changueEnd(date){
                this.endDate = date;
            },
           
        },

    }
</script>

<style scoped>
.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}
.btn-add{
  background-color: #3498DB  !important;
  color: white;
}
.btnsave{
  background-color: #00A178 !important;
  color: white;
}
.btnclean{
  background-color: #F29D35 !important;
  color: white;
}
.btndelete{
  background-color: #E3191E !important;
  color: white;
}


</style>