<template>
    <div class="white pa-4 ">
        <!-- <template v-if="reportModule && isCarsem">
        </template> -->
        <v-row class="my-2 mr-3">
            <v-col cols="12" md="4">
                <date-picker label="Fecha consulta" @dateSelected="changeDate" />
            </v-col>
            <v-col cols="12" md="1">
                <v-btn class="btn-add mx-2" @click="getCashierCut">Filtrar<v-icon>mdi-filter</v-icon></v-btn>
            </v-col>
            <v-col cols="12" md="6">

            </v-col>
            <v-col cols="12" md="1" class="d-flex justify-end">
                <v-btn class="btn-add" @click="printReport" small>
                    <v-icon>mdi-printer</v-icon>
                    
                </v-btn>
            </v-col>
        </v-row>
        <block v-if="loading"></block>
        <div>
            <!-- <GeneralFilter class="backgroundGlobal border-boxGlobal" :search="{}" :filterEndpoint="$API.invoices.getCashierCutFiltered" @emptyFilter="getCashierCut" @filtered="filterHandler" > -->
                <DxDataGrid class="" :scrolling="{ useNative: true }"    
                    :column-auto-width="true" :data-source="cashierCut" @exporting="onExporting">
                    <DxExport :enabled="true" />
                    <DxPaging :page-size="10" />
                    <DxColumn :width="columnWidth" data-field="Punto de Venta" caption="Punto de Venta" :group-index="0"></DxColumn>
                    <DxColumn :width="columnWidth" data-field="TipoDocumento" caption="Tipo Documento"></DxColumn>
                    <!-- <DxColumn data-field="Codigo Generacion Del" caption="Codigo Generacion Inicial"></DxColumn>
                    <DxColumn data-field="Codigo Generacion Al" caption="Codigo Generacion Final"></DxColumn> -->
                    <DxColumn :width="columnWidth" cell-template="templateGravada" caption="Gravada"></DxColumn>
                    <template #templateGravada="{ data }">
                        {{ numberWithCommas( data.data.Gravada ) }}
                    </template>
                    <DxColumn :width="columnWidth" cell-template="templateIva" caption="Iva"></DxColumn>
                    <template #templateIva="{ data }">
                        {{ numberWithCommas( data.data.Iva ) }}
                    </template>
                    <DxColumn :width="columnWidth" cell-template="templateIvaRetenido" caption="IvaRetenido"></DxColumn>
                    <template #templateIvaRetenido="{ data }">
                        {{ numberWithCommas( data.data.IvaRetenido ) }}
                    </template>
                    
                    <!-- <template v-if="reportModule" >
                        <DxColumn data-field="CodigoGeneracion" caption="Codigo generacion"></DxColumn>
                        <DxColumn data-field="InvoiceType" caption="Tipo Factura"></DxColumn>
                        <DxColumn data-field="InvoiceTotalGravada" caption="Total Gravada"></DxColumn>
                        <DxColumn data-field="InvoiceIvaRete1" caption="Iva Retenido"></DxColumn>
                        <DxColumn data-field="InvoiceTotalIva" caption="Total Iva"></DxColumn>
                    </template> -->
                    <DxColumn :width="columnWidth" cell-template="templateTotal" caption="Total"></DxColumn>
                    <template #templateTotal="{ data }">
                        {{ numberWithCommas( data.data.Total ) }}
                    </template>
                </DxDataGrid>
            <!-- </GeneralFilter> -->
        </div>
        <div>
            <PrintCutBox :dataProps="print"  />
        </div>
    </div>
</template>

<script>
import { DxDataGrid, DxColumn, DxPaging, DxExport } from 'devextreme-vue/data-grid'
// import GeneralFilter from '@/components/GeneralFilter.vue'
import { printToExcel } from "@/helpers/printToexcel"
import Block from "@/components/Block"
// import DateFilter from '@/components/DateFilter'
// import CustomAutoComplete from "@/components/CustomAutoComplete.vue";
import PrintCutBox from '@/components/ReportsAndPrintings/AccountigReports/PrintCutBox'
import { numberWithCommas } from "@/helpers/money";
import DatePicker from '@/components/DatePicker'
import { convertDateFormat } from '@/helpers/formatDate';
// import SearchDialog from "@/components/SearchDialog.vue";

export default {
    props: {
        reportModule :{
        type: Boolean,
        default: false
        },
        isCarsem: {
            type: Boolean,
            default: false
        }
    },
    name: "CashierCutCarsem",
    components: {
        DxColumn,
        DxDataGrid,
        // GeneralFilter,
        DxPaging,
        Block,
        DxExport,
        PrintCutBox,
        DatePicker,
        // CustomAutoComplete, SearchDialog
    },
    data() {
        return {
            ID_Seller: null,
            sellersList: [],
            loading: false,
            cashierCut: [],
            Currency: JSON.parse(localStorage.getItem('branch')).Currency,
            printList: [],
            dates: {
                start: null,
                end: null
            },
            print: {
                show: false,
                header: {},
                data: {
                    body: []
                },
                footer: {}
            
            },
            targetFormat: "yyyy-mm-dd",
        }
    },
    mounted() {
        const date = new Date()
        // this.dates.start = date.getDay() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        // this.dates.end = date.getDay() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
        this.dates.start = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate()}`;
        this.dates.end = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate()}`;
        this.getAllSellers();
        // console.log( 'this.dates ', this.dates );
        this.getCashierCut()
    },
    methods: {
        numberWithCommas( value ) {
            return `${ this.Currency }${ numberWithCommas( value ) }`;
        },
        changeDate( param ) {
            this.dates.start = convertDateFormat( param, null, this.targetFormat );
        },
        async getAllSellers() {
            try {
                const result = await this.$API.sellers.getAllSellers();
                // for( const index in result ) {
                //   const { FirstName, LastName } = result[ index ];
                //   result[ index ].itemText = `${ FirstName } ${ LastName }`;
                // }
                // // console.log('sellers ', result[0].FullName);
                this.sellersList = result;
            } catch (error) {
                console.log('error en los sellers ', error);
            }
        },
        updateSellersList(payload) {
            this.sellersList = [...payload];
        },
        setDates(dates) {
            this.dates = dates
        },
        printReport() {
            this.print.show = true;
            this.print.data.body =[ ...this.cashierCut ];
            if(isNaN(this.print.footer.tsales)){       
                const currenTcheff = this.print.footer.ting - this.print.footer.vbot; 
                this.print.footer = {
                    tsales: this.Currency + " " + numberWithCommas(this.print.footer.tsales.toFixed(2)) || 0.00,
                    tcredit: this.Currency + " " + numberWithCommas(this.print.footer.tcredit.toFixed(2)),
                    tdev: this.Currency + " " + numberWithCommas(this.print.footer.tdev.toFixed(2)),
                    tsalesCredit: this.Currency + " " + numberWithCommas(this.print.footer.tsalesCredit.toFixed(2)),
                    ting: this.Currency + " " + numberWithCommas(this.print.footer.ting.toFixed(2)),
                    vbot: this.Currency + " " + numberWithCommas(this.print.footer.vbot.toFixed(2)),
                    abt: this.Currency + " " + numberWithCommas(this.print.footer.abt.toFixed(2)),
                    tcheef: this.Currency + " " + numberWithCommas(currenTcheff.toFixed(2)),
                    Target: this.Currency + " " + numberWithCommas(this.print.footer.Target.toFixed(2)),
                    Check: this.Currency + " " + numberWithCommas(this.print.footer.Check.toFixed(2)),
                    Transfer: this.Currency + " " + numberWithCommas(this.print.footer.Transfer.toFixed(2)),
                    PhysicalMoney: this.Currency + " " + numberWithCommas(this.print.footer.PhysicalMoney.toFixed(2)),

                }
            }
            this.print.header.title = "Corte de caja";
            this.print.header.cutDate = ` ${this.dates.start}`;
            
        },
        onExporting(e) {
            printToExcel(e, "Corte de caja")
        },
        setInvoiceType(inv) {
            switch(inv) {
                case "FCF":
                return "CONSUMIDOR FINAL";
                case "CCF":
                return "CRÉDITO FISCAL";
                case "FNS":
                return "SUJETO EXCLUIDO";
                case "FEX":
                return "EXPORTACIÓN";
                case "DNC":
                return "NOTA DE CRÉDITO";
                case "DND":
                return "NOTA DE DÉBITO";
                default:
                return "Tipo de factura desconocido";
            }
        },
        callbackReduce( accumulator, current ){
            const { Total, IdTipoDocumento, FeMhEstado, TipoDocumento } = current;
            return IdTipoDocumento && FeMhEstado != 'INVALIDADO' && TipoDocumento != 'NOTA DE CRÉDITO' ? accumulator += Total : accumulator;
        },
        callbackReduceInvalidate( accumulator, current ){
            const { Total, FeMhEstado, TipoDocumento } = current;
            return FeMhEstado === 'INVALIDADO' || TipoDocumento === 'NOTA DE CRÉDITO' ? accumulator += Total : accumulator;
        },
        callbackReduceDNC( accumulator, current ){
            const { Total, TipoDocumento } = current;
            return TipoDocumento === 'NOTA DE CRÉDITO' ? accumulator += Total : accumulator;
        },
        callbackReduceAbonos( accumulator, current ){
            const { Total, InvoiceStatusDescription, FeMhEstado, TipoDocumento } = current;
            return InvoiceStatusDescription === 'ABONO' && FeMhEstado != 'INVALIDADO' && TipoDocumento != 'NOTA DE CRÉDITO' ? accumulator += Total : accumulator;
        },
        callbackReduceCredit( accumulator, current ){
            const { Total, InvoiceStatusDescription, FeMhEstado, TipoDocumento } = current;
            return InvoiceStatusDescription === 'Crédito' && FeMhEstado != 'INVALIDADO' && TipoDocumento != 'NOTA DE CRÉDITO' ? accumulator += Total : accumulator;
        },
        callbackReduceIncomings( accumulator, current ) {
            const { Total, InvoiceStatusDescription, InvoicePaymentType, FeMhEstado, TipoDocumento } = current;
            return ( ( InvoicePaymentType === 'Billetes y monedas' || InvoicePaymentType === 'Cheque' ) && ( InvoiceStatusDescription === 'Cancelada' || InvoiceStatusDescription === 'ABONO' ) && FeMhEstado != 'INVALIDADO' && TipoDocumento != 'NOTA DE CRÉDITO' ) ? accumulator += Total : accumulator;
        },
        callbackReduceOtherIncomings( accumulator, current ) {
            const { Total, InvoiceStatusDescription, InvoicePaymentType } = current;
            return ( InvoicePaymentType != 'Billetes y monedas' && InvoicePaymentType != 'Cheque' && InvoicePaymentType != 'Tarjeta Débito' && InvoicePaymentType != 'Tarjeta Crédito' && InvoicePaymentType != 'Transferencia_ Depósito Bancario' ) && ( InvoiceStatusDescription === 'Cancelada' || InvoiceStatusDescription === 'ABONO' ) ? accumulator += Total : accumulator;
        },
        callbackReduceOtherIncomingsDev( accumulator, current ) {
            const { Total, InvoiceStatusDescription, InvoicePaymentType } = current;
            return ( InvoicePaymentType != 'Billetes y monedas' && InvoicePaymentType != 'Cheque' && InvoicePaymentType != 'Tarjeta Débito' && InvoicePaymentType != 'Tarjeta Crédito' && InvoicePaymentType != 'Transferencia_ Depósito Bancario' ) && ( InvoiceStatusDescription === 'NOTA DE CREDITO' || InvoiceStatusDescription === 'INVALIDADO' ) ? accumulator += Total : accumulator;
        },
        callbackReduceCard( accumulator, current ) {
            const { Total, InvoiceStatusDescription, InvoicePaymentType } = current;
            return ( InvoicePaymentType === 'Tarjeta Débito' || InvoicePaymentType === 'Tarjeta Crédito' ) && ( InvoiceStatusDescription === 'Cancelada' || InvoiceStatusDescription === 'ABONO' )  ? accumulator += Total : accumulator;
        },
        callbackReduceCardDev( accumulator, current ) {
            const { Total, InvoiceStatusDescription, InvoicePaymentType } = current;
            return ( InvoicePaymentType === 'Tarjeta Débito' || InvoicePaymentType === 'Tarjeta Crédito' ) && ( InvoiceStatusDescription === 'NOTA DE CREDITO' || InvoiceStatusDescription === 'INVALIDADO' )  ? accumulator += Total : accumulator;
        },
        callbackReduceCheck( accumulator, current ) {
            const { Total, InvoiceStatusDescription, InvoicePaymentType } = current;
            return InvoicePaymentType === 'Cheque' && ( InvoiceStatusDescription === 'Cancelada' || InvoiceStatusDescription === 'ABONO' ) ? accumulator += Total : accumulator;
        },
        callbackReduceCheckDevs( accumulator, current ) {
            const { Total, InvoiceStatusDescription, InvoicePaymentType } = current;
            return InvoicePaymentType === 'Cheque' && ( InvoiceStatusDescription === 'NOTA DE CREDITO' || InvoiceStatusDescription === 'INVALIDADO' ) ? accumulator += Total : accumulator;
        },
        callbackReduceTransfer( accumulator, current ) {
            const { Total, InvoiceStatusDescription, InvoicePaymentType } = current;
            return InvoicePaymentType === 'Transferencia_ Depósito Bancario' && ( InvoiceStatusDescription === 'Cancelada' || InvoiceStatusDescription === 'ABONO' ) ? accumulator += Total : accumulator;
        },
        callbackReduceTransferDevs( accumulator, current ) {
            const { Total, InvoiceStatusDescription, InvoicePaymentType } = current;
            return InvoicePaymentType === 'Transferencia_ Depósito Bancario' && ( InvoiceStatusDescription === 'NOTA DE CREDITO' || InvoiceStatusDescription === 'INVALIDADO' ) ? accumulator += Total : accumulator;
        },
        callbackReducePhysicalMoney( accumulator, current ) {
            const { Total, InvoiceStatusDescription, InvoicePaymentType } = current;
            return InvoicePaymentType === 'Billetes y monedas' && ( InvoiceStatusDescription === 'Cancelada' || InvoiceStatusDescription === 'ABONO' ) ? accumulator += Total : accumulator;
        },
        callbackReducePhysicalMoneyDevs( accumulator, current ) {
            const { Total, InvoiceStatusDescription, InvoicePaymentType } = current;
            return InvoicePaymentType === 'Billetes y monedas' && ( InvoiceStatusDescription === 'NOTA DE CREDITO' || InvoiceStatusDescription === 'INVALIDADO' ) ? accumulator += Total : accumulator;
        },
        setupData(data){
            this.print.footer = {
                tsales : 0,
                tcredit : 0,
                tdev:0,
                tsalesCredit : 0,
                ting: 0,
                vbot: 0,
                abt: 0,
                tcheef: 0,
                card: 0,
                Check: 0,
                Transfer: 0,
                PhysicalMoney: 0,

            }

            this.print.footer.tsales = data.reduce( this.callbackReduce, 0.00 );
            this.print.footer.tdev = data.reduce( this.callbackReduceInvalidate, 0.00 );
            this.print.footer.tcredit = data.reduce( this.callbackReduceAbonos, 0.00 );
            this.print.footer.tsalesCredit = data.reduce( this.callbackReduceCredit, 0.00 );
            const { footer: { tsales, tdev, tcredit, tsalesCredit } } = this.print
            this.print.footer.ting = tsales - tdev - tsalesCredit + tcredit;
            this.print.footer.vbot = 0.00
            this.print.footer.abt = data.reduce( this.callbackReduceOtherIncomings, 0.00 ) - data.reduce( this.callbackReduceOtherIncomingsDev, 0.00 );
            this.print.footer.card = data.reduce( this.callbackReduceCard, 0.00 ) - data.reduce( this.callbackReduceCardDev, 0.00 );
            this.print.footer.Check = data.reduce( this.callbackReduceCheck, 0.00 ) - data.reduce( this.callbackReduceCheckDevs, 0.00 );
            this.print.footer.Transfer = data.reduce( this.callbackReduceTransfer, 0.00 ) - data.reduce( this.callbackReduceTransferDevs, 0.00 );
            console.log( 'data.reduce( this.callbackReducePhysicalMoney, 0.00 ) - data.reduce( this.callbackReducePhysicalMoneyDevs, 0.00 ) ', data.reduce( this.callbackReducePhysicalMoney, 0.00 ), data.reduce( this.callbackReducePhysicalMoneyDevs, 0.00 ), data.reduce( this.callbackReducePhysicalMoney, 0.00 ) - data.reduce( this.callbackReducePhysicalMoneyDevs, 0.00 ) );
            this.print.footer.PhysicalMoney = data.reduce( this.callbackReducePhysicalMoney, 0.00 ) - data.reduce( this.callbackReducePhysicalMoneyDevs, 0.00 );
            this.print.footer.tcheef = data.reduce( this.callbackReduceIncomings, 0.00 );

            // console.log( this.print.footer );
            // for( const item of data ) {
            //     if(item.IdTipoDocumento === "01" || item.IdTipoDocumento === "03" || item.IdTipoDocumento === "11" ){
            //         this.print.footer.tsales += parseFloat(item.Total);
            //     }
            //     if(item.IdTipoDocumento === "03" || item.IdTipoDocumento === "05"){
            //         this.print.footer.tsalesCredit += parseFloat(item.Total);
            //     }
            //     if( (item.InvoiceType === "CCF" || item.InvoiceType === "FCF" || item.InvoiceType === "FEX") && (item.InvoicePaymentType == "Billetes y monedas" || item.InvoicePaymentType == "Cheque")){
            //         this.print.footer.ting += parseFloat(item.TotalSale);
            //     }
            //     // console.log(this.print.footer.tsales);
            // }
            // return data.map(item => {
                
                // if(item.InvoiceType === "CCF" || item.InvoiceType === "FCF" || item.InvoiceType === "FEX"){
                //     this.print.footer.tsales += parseFloat(item.TotalSale);
                // }
                // if(item.InvoiceType === "FCF" || item.InvoiceType === "DNC"){
                //     this.print.footer.tsalesCredit += parseFloat(item.TotalSale);
                // }

                // if( (item.InvoiceType === "CCF" || item.InvoiceType === "FCF" || item.InvoiceType === "FEX") && (item.InvoicePaymentType == "Billetes y monedas" || item.InvoicePaymentType == "Cheque")){
                //     this.print.footer.ting += parseFloat(item.TotalSale);
                // }
                // else if((item.InvoiceType === "CCF" || item.InvoiceType === "FCF" || item.InvoiceType === "FEX" ) && (item.InvoicePaymentType != "Billetes y monedas" && item.InvoicePaymentType != "Cheque")){
                //     this.print.footer.vbot += parseFloat(item.TotalSale);
                // }

                // return {
                //     ...item,
                //     InvoiceType: this.setInvoiceType(item.InvoiceType),
                //     InvoiceTotalGravada: this.Currency + " " + item.InvoiceTotalGravada.toFixed(2),
                //     InvoiceIvaRete1: this.Currency + " " + item.InvoiceIvaRete1.toFixed(2),
                //     InvoiceTotalIva: this.Currency + " " + item.InvoiceTotalIva.toFixed(2),
                // }
            // });
        },
        getCashierCut() {
            // console.log(this.dates);
            this.loading = true
            // this.$API.invoices.getCashierCut()
            //     .then(response => {
            //         if(response.length === 0){
            //             this.loading = false
            //             return this.cashierCut = [];
            //         }
            //         if(this.reportModule){
            //             this.cashierCut = this.setupData(response)
            //             this.loading = false 
            //         }
            //         else{
            //             this.cashierCut = response
            //             this.loading = false
            //         }
            //     })
            this.$API.invoices.getCashierCutByDates( this.dates )
                .then(response => {
                    const { data, dataDetallada } = response
                    this.setupData( dataDetallada );
                    this.cashierCut = [ ...data ];
                    this.loading = false
                })
        },
        filterHandler(response) {
            if(this.reportModule){
                this.cashierCut = this.setupData(response)
             
            }
            else{
                this.cashierCut = response
            }
        },
        dateFilterHandler(filteredData) {
            // if(this.reportModule){
            //     this.cashierCut = this.setupData(filteredData)
            // }
            // else{
            // }
            this.cashierCut = filteredData
        },
        sellerSelectedFromDialog(data){
            this.searchSellerDialog = false;
            this.ID_Seller = data.ID_Seller
        },
    },
    computed:{
        columnWidth() {
    
    return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
  },
    }
    
}

</script>

<style scoped>
.btn-add {
  background-color: #3498db !important;
  color: white;
}


.item{
    @media screen and (min-width: 1068px) {
        position: absolute;
        z-index: 1;
    }
    @media screen and (max-width: 900px) {
        padding-bottom: 20px;
    }
}
</style>