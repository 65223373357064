<template>
    <window-portal :open="dataProps.show" @close="() => {
        dataProps.show = false
    }" >
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center><div><p style="font-size: 30px;">Vista previa de impresión Resumen Cuentas Vencidas a 30 Días</p></div></center>
            <br/>
            <center>
                <button class="btnclean" style="font-size: 18px;" @click="Print()">
                    Imprimir
                </button>
                &nbsp;&nbsp;
                <button class="btnclean" style="font-size: 18px;" @click="Download()">
                    Descargar
                </button>
             
                
            </center>
            <center><div v-if="loading"><p>Cargando...</p></div></center>

        </div>
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center v-if="mailLabel">
                {{ mailLabel }}
            </center>
        </div>

        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">
                <div style="margin: 0 auto; width: 1000px;">
                    <div ref="dataprint" style="margin: 0 !important; padding: 0 !important;">
                        <!--div style="position: relative; width: 1000px; min-height: 1294px; background: white;"-->
                        <div v-for="(page, indexPage) in pages" :key="indexPage" style="position: relative; width: 1000px; min-height: 1293px; background: white;">
                            <hr/>
                            <div class="subpage" style="height: 1250px; padding-top: 20px; overflow: hidden;">
                                <div style="margin-left: 80px; margin-right: 50px;">
                                    <div style=" font-size: 12px;">
                                        <hr />
                                        <hr />
                                        <div style="display: flex; justify-content: space-between;">
                                            <h2><i>{{ dataProps.header.title }}</i></h2>
                                            <p style="margin-top: 20px ;">Fecha Solicitada: {{ dataProps.header.dateperiod  }} </p>
                                            <p style="margin-top: 20px ;">Fecha de emisión: {{ dataProps.header.date  }} </p>
                                        </div>
                                       
                                                  
                                    </div>
                                    
                                 
                                    <!-- Heres is teh body -->
                                    <div style="width: 100%;">
                                        <table  style="width: 100% !important; font-size: 11px; border-collapse: collapse; margin-bottom: 25px;">
                                            <tr>
                                                <th style=" padding-left: 2px;" colspan="13">
                                                    {{ `ORDENADO POR RAZÓN SOCIAL` }}
                                                </th>
                                            </tr>
                                            <tr>
                                                <td colspan="13">

                                                    <hr />
                                                    <hr />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colspan="3" style=" padding-left: 2px; text-align: end;">Código del cliente</td>
                                                <td colspan="3" style=" padding-left: 2px; text-align: end;">Razón Social</td>
                                                <td style=" padding-left: 2px; text-align: end;">Vendedor Responsable</td>
                                                <td style=" padding-left: 2px; text-align: end;">Saldo sin Vencimiento</td>
                                                <td style=" padding-left: 2px; text-align: end;">Saldo Vencido</td>
                                                <td style=" padding-left: 2px; text-align: end;">Total</td>
                                            </tr>
                                            <tr>
                                                <td colspan="13">

                                                    <hr />
                                                    <hr />
                                                </td>
                                            </tr>
                                            <tr   v-for="(item, index) in valuesRows[indexPage]" :key="index">
                                               
                                                
                                                <td colspan="3" style="padding: 4px;"  >{{ item[ "ID_Customer" ] }}</td>
                                                <td colspan="3" style="padding: 4px;"  >{{ item[ "LegalName" ] }}</td>
                                                <td style="padding: 4px; text-align: end !important;"  >{{ item[ "SellerAssigned" ] }}</td>
                                                <td style="padding: 4px; text-align: end !important;"  >{{ item[ "NotExpiredBalanceFormatted" ] }}</td>
                                                <td style="padding: 4px; text-align: end !important;"  >{{ item[ "ExpiredBalanceFormatted" ] }}</td>
                                                <td style="padding: 4px; text-align: end !important;"  >{{ item[ "TotalFormatted" ] }}</td>
                                            </tr> 
                                            <tr v-if="indexPage === numPages - 1"> 
                                               <td colspan="3" style="padding: 4px;"  ></td>
                                               <td colspan="3" style="padding: 4px;"  ></td>
                                               <td style="padding: 4px; text-align: end;"  ><strong>TOTALES</strong></td>
                                               <td style="padding: 4px; text-align: end;"  ><strong>{{ calcNumberwithCommas( totalPaid ) }}</strong></td>
                                               <td style="padding: 4px; text-align: end;"  ><strong>{{ calcNumberwithCommas( totalOutstandingBalance ) }}</strong></td>
                                               <td style="padding: 4px; text-align: end;"><strong>{{ calcNumberwithCommas( totalTotal ) }}</strong></td>
                                           </tr>
                                            

                                        </table>
                                        <br/>
                                        
                                    </div>
                                  
                                </div>

                               
                                
                                <div style="position: absolute; bottom: 0; width: 100%; left: 80px">
                                    <p style="font-size:10px;">Página {{ indexPage+1 }} de {{ numPages }}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
//import QRCode from 'qrcode';
//import html2canvas from "html2canvas"
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue" 
import { numberWithCommas } from "@/helpers/money";
import { removeDuplicates } from "@/helpers/object";


export default {
    name: "ExpiredAccointsReceivableReports",
    props: ['dataProps'],
    components: {WindowPortal},
    data() {
        return {
            header: null,
            footer: null,
            loading: false,
            //seal: "",
            //sealBranch: "",
            numPages: 0,
            Currency:  JSON.parse(localStorage.getItem('branch')).Currency || '',
            //valuesRows: []
            mailLabel: null,
            valuesRows: [],
            valuesRows2: [],
            urlQr: null,
            typePrint: 0,
            recordsByPointSales: []
        }
    },
    mounted() {
        this.calculatePagesOfDoc()
    },
    computed: {
        /*edad() {
            let edad = this.dataProps.data.profile.Age
            if (edad == null || edad == undefined) {
                return ''
            } else {
                return edad
            }
        },*/
        pages() {
            // Generar un arreglo con `numPages` elementos
            console.log(Array.from({ length: this.numPages }));
            return Array.from({ length: this.numPages });
        },
        totalTotal() {
            return this.dataProps.data.body.reduce( ( accum, current ) => {
                const { Total } = current;
                return accum + Total
            }, 0.00 );
        },
        totalOutstandingBalance() {
            return this.dataProps.data.body.reduce( ( accum, current ) => {
                const { ExpiredBalance } = current;

                return accum + ExpiredBalance
            }, 0.00 );
        },
        totalPaid() {
            return this.dataProps.data.body.reduce( ( accum, current ) => {
                const { NotExpiredBalance } = current;

                return accum + NotExpiredBalance
            }, 0.00 );
        }
    },
    watch: {
        'dataProps.show'() {
            if(this.dataProps.show){
                setTimeout(async () => {
                    await this.calculatePagesOfDoc()
                }, 0);
            }
        },
        'dataProps.data.body'() {
            // this.groupByPointSale();
            this.calculatePagesOfDoc()
        }
    },
    methods: {
        calcNumberwithCommas(number){
            // replace currency symbol with equiva
            typeof number === 'number' && ( number = number.toString() );
            let n = number.replace(this.Currency, "")
            return  this.Currency + numberWithCommas(n)
        },
        groupByPointSale() {
            const arrayFromRecords = [ ...this.dataProps.data.body ];

            const arrayOfPointSales = arrayFromRecords.map( current => {
                const { "Punto de Venta": pointSale } = current;

                return pointSale
            } );

            const arrayOfPointSalesUnique = removeDuplicates( arrayOfPointSales );

            for( const salePoint of arrayOfPointSalesUnique ) {
                for( const testingSalePoint of this.testingPointSales ) {
                    const objToPush = {
                        salePoint: testingSalePoint,
                        records: arrayFromRecords.filter( ( { "Punto de Venta": pointSaleInRecord } ) => {
                            return pointSaleInRecord === salePoint
                        } )
                    };
    
                    this.recordsByPointSales.push( objToPush );
                }
            }

            console.log( this.recordsByPointSales );
        },
        calculatePagesOfDoc() {
            let pages = 0;
            // let pages2 = 0;
            let rows = 0;
            // let rows2 = 0;
            // let usedRows = 0
            this.valuesRows[pages] = [];
            // this.valuesRows2[pages2] = [];

            try{
                // for( const index in this.recordsByPointSales ) {
                //     const { records, salePoint } = this.recordsByPointSales[ index ];
                    
                //     const currentPayload = {
                //         salePoint,
                //         records: []
                //     };
                //     rows2++;

                //     if( rows2 === 20 ) {
                //         pages2++;
                //         this.valuesRows2[pages2] = []; 
                //         rows2 = 1;
                //     }
                //     for( const indexR in records ) {
                //         currentPayload.records.push( records[ indexR ] );
                //         rows2++;
                //         if( rows2 === 20 ) {
                //             this.valuesRows2[pages2].push( { ...currentPayload } );
                //             currentPayload.records = [];
                //             pages2++;
                //             this.valuesRows2[pages2] = []; 
                //             rows2 = indexR === records.length - 1 ? 0 : 1 ;
                //         }
                //     }
                //     this.valuesRows2[ pages2 ].push( { ...currentPayload } );
                // }

                // console.log(this.valuesRows2);
                // do {
                // }  while( rows < 20 && rows < ( this.dataProps.data.body.length + this.recordsByPointSales.length ) )
                
                    this.typePrint = 0
                    //Cuando es solo una página
                    if(this.dataProps.data.body.length <= 20){
                        for(let i = 0; i < this.dataProps.data.body.length; i++){
                            rows++
                            // console.log( 'this.dataProps.data.body[i] ', this.dataProps.data.body[i] );
                            this.valuesRows[pages].push(this.dataProps.data.body[i])
                            // console.log( this.valuesRows[ pages ] );
                        }    
                    }
                    else{
                        for(let i = 0; i < this.dataProps.data.body.length; i++){
                            if(rows < 20 && (i !== this.dataProps.data.body.length - 1)){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                            }
                            else if(rows <= 20){
                                rows++
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                            }
                            else{
                                this.valuesRows[pages].push(this.dataProps.data.body[i])
                                pages++
                                rows = 0
                                this.valuesRows[pages] = []
                            }
                        }
                    }
                
                
            }
            catch(err) {
                console.log(err);
                alert(err)
            }
            // pages = pages + 1

            this.numPages = pages + 1;
            console.log(this.numPages);
            // this.numPages = pages2;
        },

        async Print(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            })
            
            
            //doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        },

        async Download(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            });
            
            //doc.deletePage(2)
        doc.save(this.dataProps.header.title+ "_"+this.dataProps.header.date.replaceAll("/","-") + "_"+".pdf"); //Agregado por Juan 13082023

            this.loading = false
        },
        async sendMail() {
            try {
                this.mailLabel = null;
                var doc = new jsPDF('p', 'pt', 'letter')
                //console.log(doc);
                //console.log(this.$refs.dataprint);
                await doc.html(this.$refs.dataprint, {
                    callback: function(doc){
                        doc.output()
                    },
                    x: 0,
                    y: 0,
                    margin: [0, 0, 0, 0],
                    html2canvas:{ 
                        scale: 0.612,
                        //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                        useCORS: true,
                        logging: false,
                        allowTaint: true
                    },
                });
                
                const body = {
                    dtefilebuffer: new Buffer.from(doc.output('arraybuffer')),
                    dtejson: this.dataProps.data.dteJson,
                    dtefilebufferfilename: this.dataProps.header.title+ "_"+this.dataProps.header.date.replaceAll("/","-") + "_"+".pdf"
                };

                // console.log(body);
                this.$API.invoices.sendInvoiceMail(body);

                this.mailLabel = 'Se envió el correo correctamente';
                this.$emit('mailSent');
            } catch (error) {
                this.mailLabel = 'Ocurrió un error al enviar el correo';
                console.log(error);
            }
        },
        async downLoadJSON() {
            try {
                const aElement = document.createElement('a');
                aElement.setAttribute('download', this.dataProps.header.title+ "_"+this.dataProps.header.date.replaceAll("/","-") + "_"+".pdf");
                const blob = new Blob([JSON.stringify(this.dataProps.data.dteJson)], { type: 'application/json' });
                const href = URL.createObjectURL(blob);
                aElement.href = href;
                aElement.download = this.dataProps.header.title+ "_"+this.dataProps.header.date.replaceAll("/","-") + "_"+".json";
                aElement.click();
                URL.revokeObjectURL(href);
            } catch (error) {
                this.mailLabel = 'Ocurrió un error al enviar el correo';
                console.log(error);
            }
        }
    }
}
</script>

<style scoped>
    .u-row .u-col-100{
        width: 100% !important;
    }
    .btnclean{
        border-radius: 15px;
        border: 1px solid white;
  background-color: #F29D35 !important;
  color: white !important;
}
    .btnclose{
        border-radius: 15px;
        border: 1px solid white;
        background-color: rgb(255, 38, 38) !important;
        color: white !important;
       
    }

    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }

 

</style>
