<template>
    <div class="white pa-4">
        <Block v-if="loading" />
        
        <v-row class="mt-2">
            
            <v-col cols="12" md="12" class="d-flex justify-end">
                <v-btn class="btn-add" @click="setupPrint">
                    <v-icon>mdi-printer</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <VersatileFilter :columnsSearch="columsConfig" :search="search" @emptyFilter="getData" 
           :disableDates="true"
                 @filtered="filterHandler"
                        :key="Reloadfilter" :columnfilterEndpoint="$API.inventoryReports.AccountsReceivableSummaryColReport"
                       @enabledate="(e) => {useDate = e;}"
                >
            <DxDataGrid 
            :dataSource="DataTable"
            :columns="columnsList"
            @exporting="onExporting"
            :word-wrap-enabled="true"
            :scrolling="{ useNative: true }"    
                    :column-auto-width="true"
            >
                <DxExport :enabled="true" />

                <DxPager :showPageSizeSelector="true" :allowedPageSizes="[10, 30, 50]" />
                <DxPaging :page-size="10" />
            </DxDataGrid>
        </VersatileFilter>

        <alerts
            v-if="alert.show"
            v-on:close_alert="closeAlert"
            v-on:accept_alert="acceptAlert"
            :properties="alert"
        />
        <div>
            <component v-for="(item, index) in PrintList" :key="index" :is="item.component" v-bind="item.props" />
        </div>
    </div>
</template>

<script>
    
    //import {mapState} from 'vuex';
    import VersatileFilter from '@/components/VersatileFilter.vue';
    import { DxDataGrid, DxPager, DxPaging, DxExport } from 'devextreme-vue/data-grid';
    import Block from '@/components/Block.vue';
    import Alerts from '@/components/Alerts.vue';
    import { printToExcel } from "@/helpers/printToexcel";
    import { numberWithCommas } from "@/helpers/money";
    
    import PrintResumeAccountsReceivable from "@/components/ReportsAndPrintings/AccountigReports/PrintResumeAccountsReceivable";
    export default {
        name: "ResumeAccountsReceivable",
        components: {
            DxDataGrid,
            DxPager,
            DxPaging,
            Block,
            Alerts,
            DxExport,
            VersatileFilter,
        },
        props: ['win', 'business','datafilter'],
        data() {
            return {
                DataTable: [],
                panel: 0,
                tab: null,
                key: 0,
                loading: false,
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                startDate: null,
                endDate: null,
                useDate : false,
                SellerList: [],
                seller: null,
                columnsList: [

                { dataField: "ID_Customer", caption: "Codigo"},
                { dataField: "LegalName", caption: "Razon Social" },
                { dataField: "Docs", caption: "No. Docs" },
                { dataField: "CreditStatus", caption: "Estado del credito" },
                { dataField: "CreditPeriod", caption: "Plazo" },
                { dataField: "LimitCredit", caption: "Limite" },
                { dataField: "LastDatePayment", caption: "Ult. Abono" },
                { dataField: "ShowBalance_0_to_30_Days", caption: "0-30 dias" },
                { dataField: "ShowBalance_31_to_60_Days", caption: "31-60 dias" },
                { dataField: "ShowBalance_61_to_90_Days", caption: "61-90 dias" },
                { dataField: "ShowBalance_91_to_120_Days", caption: "91-120 dias" },
                { dataField: "ShowOver121", caption: "120 -> dias" },
                { dataField: "ShowOverdueBalance", caption: "Saldo Vencido" },
                { dataField: "ShowTotal", caption: "Total" },

             
                ],
                columsConfig: [
                    {
                        name: "ID_Customer",
                        title: "ID Cliente",
                        value: '',
                        type: "text",
                      
                    },
                    {
                        name: "LegalName",
                        title: "Razon Social",
                        value: '',
                        type: "text",
                    }
                ],
                Currency: JSON.parse(localStorage.getItem('branch')).Currency || "",
                search: {
                   
                },
                summary: {
                    Total: 0,
                },
                Reloadfilter: 0,
                PrintList: [],
                print: {
                    show: false,
                    header: {
                    },
                    data: [],
                },
            }
        },
        computed:{
            
        },
        mounted() {
            //this.assignParams();
           this.loadData();
        },
        methods: {
            handleShowData(data){
             
                if(data.length > 0){
                    this.DataTable = data.map(item => {
                        item.ShowBalance_0_to_30_Days = this.Currency + ' '+numberWithCommas(item.Balance_0_to_30_Days);
                        item.ShowBalance_31_to_60_Days = this.Currency +' '+ numberWithCommas(item.Balance_31_to_60_Days);
                        item.ShowBalance_61_to_90_Days = this.Currency +' '+ numberWithCommas(item.Balance_61_to_90_Days);
                        item.ShowBalance_91_to_120_Days = this.Currency +' '+ numberWithCommas(item.Balance_91_to_120_Days);
                        item.ShowOver121 = this.Currency +' '+ numberWithCommas(item.Over121);
                        item.ShowOverdueBalance = this.Currency +' '+ numberWithCommas(item.OverdueBalance);
                        item.ShowTotal = this.Currency +' '+ numberWithCommas(item.Total);
                        item.LimitCredit = this.Currency +' '+ numberWithCommas(item.LimitCredit);
                        return item;
                    });
                }
                else{
                    this.DataTable = [];
                }
              
             
            },
            loadData(){
                    this.getData();
                
            },
            assignParams(){
                console.log('datafilter',this.datafilter);
            },
            setupPrint(){
                this.print.data.body = this.DataTable;
                this.print.header.title = "Resumen de Cuentas por Cobrar";
               
                const date = new Date();
                
                this.print.header.summary = this.summary;
                this.print.header.business = this.business;
                //date wiht format dd/mm/yyyy
                this.print.header.cutDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

                this.print.show = true;
                 this.PrintList.push({component: PrintResumeAccountsReceivable, props: {
                    dataProps: this.print,
                }}); 
            },
            onExporting(e) {
              printToExcel(e, "Resumen  Cuentas por Cobrar");
            },
            filterHandler(data){
            
                if(data.length > 0){
                    this.handleShowData(data);
                    return;
                }
                else{
                    this.DataTable = [];
                }
                
            },
            async getData(){
                this.loading = true;
                
                this.Reloadfilter++;
               
                try {
                    let response = await this.$API.inventoryReports.AccountsReceivableSummary();
                    if(response.length > 0){
                        this.handleShowData(response);
                    }
                    else{
                        this.DataTable = [];
                    }
                    
                    this.loading = false;    
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                    this.showAlert('danger', 'Error', error);
                }
                this.loading = false;
            },
            closeAlert() {
                this.alert.show = false; 
            },
            acceptAlert() {
                this.alert.show = false;
            },
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },
            changueStr(date){
                this.startDate = date;
            },
            changueEnd(date){
                this.endDate = date;
            },
           
        },

    }
</script>

<style scoped>
.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}
.btn-add{
  background-color: #3498DB  !important;
  color: white;
}
.btnsave{
  background-color: #00A178 !important;
  color: white;
}
.btnclean{
  background-color: #F29D35 !important;
  color: white;
}
.btndelete{
  background-color: #E3191E !important;
  color: white;
}


</style>