<template>
    <window-portal :open="dataProps.show">
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center>
                <div>
                    <p style="font-size: 30px;">Vista previa de impresión cuentas por cobrar</p>
                </div>
            </center>
            <br />
            <center>
                <button class="btnclean" style="font-size: 18px;" @click="Print()">
                    Imprimir
                </button>
                &nbsp;&nbsp;
                <button class="btnclean" style="font-size: 18px;" @click="Download()">
                    Descargar
                </button>


            </center>
            <center>
                <div v-if="loading">
                    <p>Cargando...</p>
                </div>
            </center>

        </div>
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center v-if="mailLabel">
                {{ mailLabel }}
                {{ mailLabel }}
            </center>
        </div>

        <div class="book"
            style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">
            <div style="margin: 0 auto; width: 1000px;">
                <div ref="dataprint" style="margin: 0 !important; padding: 0 !important;">
                    <!--div style="position: relative; width: 1000px; min-height: 1294px; background: white;"-->
                    <div v-for="(page, indexPage) in pages" :key="indexPage"
                        style="position: relative; width: 1000px; min-height: 1293px; background: white;">
                        <div class="subpage" style="height: 1250px; padding-top: 20px; overflow: hidden;">
                            <div style="margin-left: 80px; margin-right: 50px;">
                                <div style=" font-size: 12px;">

                                    <v-container>
                                        <div
                                            style="display: flex; justify-content: space-between; flex-direction: row;">
                                            <v-col style="width: 33.33%; text-align: center;">
                                                <img ref="logo" :src="showLogo" width="200px" height="auto" />
                                            </v-col>
                                            <v-col
                                                style="width: 33.33%; text-align: center; display: flex; flex-direction: column; align-items: center; justify-content: center; margin-top: 10px;">
                                                <span style="font-size: 18.3px">ESTADO DE CUENTA</span>
                                            </v-col>
                                            <v-col
                                                style="width: 33.33%; text-align: center; display: flex; flex-direction: column; align-items: center; justify-content: center; margin-top: 10px;">
                                                <span style="font-size: 15.5px">{{ businessData.address }}</span>
                                                <span style="font-size: 15.5px">Teléfono: {{ businessData.phone }}</span>
                                            </v-col>
                                        </div>
                                    </v-container>

                                    <hr />
                                    <hr />
                                    <v-container>
                                        <div
                                            style="display: flex; justify-content: space-between; flex-direction: row;">
                                            <v-col style="text-align: center; font-size: 15px;">
                                                <v-col style="display: flex; justify-content: start; flex-direction: row; margin-bottom: 10px;">CLIENTE: <span style="margin-left: 24.3px;">{{ dataCustomer.value }}</span><span style="margin-left: 15px;">{{ dataCustomer.name }}</span></v-col>
                                                <v-col style="display: flex; justify-content: start; flex-direction: row;">DIRECCIÓN: {{ dataCustomer.address }}  </v-col>
                                            </v-col>
                                            <v-col style="text-align: center; font-size: 15px;">
                                                <v-col style="display: flex; justify-content: start; flex-direction: row; margin-bottom: 10px">FECHA: {{ getCurrentDate() }}</v-col>
                                                <!-- <v-col style="display: flex; justify-content: start; flex-direction: row;">PLAZO:  </v-col> -->
                                            </v-col>
                                        </div>
                                    </v-container>
                                    <br>
                                </div>

                                <br />
                                <!-- Heres is the body -->
                                <div style="width: 100%;">
                                    <table style="width: 100% !important; font-size: 14px; border-collapse: collapse;">
                                        <tr style="border-bottom: 1px solid black; padding-bottom: 10px;">
                                            <td style="margin: 4px 10px;text-align: end ; width: 50px;"></td>
                                            <td style="margin: 4px 10px;text-align: center ;">NO. DOCUMENTO</td>
                                            <td style="margin: 4px 10px;text-align: end ">FECHA</td>
                                            <td style="margin: 4px 10px;text-align: end ">VENCIMIENTO</td>
                                            <td style="margin: 4px 10px;text-align: end ">MENOR <br> 15 DÍAS</td>
                                            <td style="margin: 4px 10px;text-align: end ">MAYOR <br> 15 DÍAS</td>
                                            <td style="margin: 4px 10px;text-align: end ">SALDO <br> VENCIDO</td>
                                            <td style="margin: 4px 10px;text-align: end ; width: 50px;"></td>
                                        </tr>

                                        <tr style="height:10px;"></tr>
                                        <tr v-for="(item, index) in valuesRows[indexPage]" :key="index"
                                            style="padding-bottom: 10px;">
                                            <td style="width: 50px;"></td>
                                            <td style="height: 28px;text-align: center;">{{ item.ID_Invoice }}</td>
                                            <td style="height: 28px; text-align: end;">{{ item.FormattedInvoiceDate }}</td>
                                            <td style="height: 28px; text-align: end;">{{ item.FormattedExpirationDate }}</td>
                                            <td style="height: 28px;text-align: end;">{{ item.Minor15 > 0 ? item.Minor15Formatted : "" }}</td>
                                            <td style="height: 28px;text-align: end;">{{ item.Major15 > 0 ? item.Major15Formatted : "" }}</td>
                                            <td style="height: 28px; color: #C93C20; font-weight: bold; text-align: end;">
                                                {{ item.ExpiredBalance > 0 ? item.ExpiredBalanceFormatted : "" }}</td>
                                            <td style="width: 50px;"></td>
                                        </tr>

                                        <tr style="height:10px;"></tr>
                                        <tr v-if="(indexPage + 1) === numPages"
                                            style="border-top: 1px solid black; padding-top: 10px;">
                                            <td style="width: 50px;"></td>
                                            <td style="height: 50px; font-weight: bold; text-align: end;"></td>
                                            <th style="height: 50px; font-weight: bold; text-align: end;" colspan="2">TOTALES</th>
                                            <td style="height: 50px; font-weight: bold; text-align: end;">{{ total.TotalNewShow }}</td>
                                            <td style="height: 50px; font-weight: bold; text-align: end;">{{ total.TotalOldShow }}</td>
                                            <td style="height: 50px; font-weight: bold; text-align: end; color: #C93C20;">{{total.TotalPayDueShow }}</td>
                                            <td style="font-weight: bold; text-align: end; width: 80px;">{{ total.TotalAllShow }}</td>
                                        </tr>

                                    </table>
                                    <br />
                                </div>

                            </div>

                            <div style="position: absolute; bottom: 0; width: 100%; left: 80px">
                                <p style="font-size:10px;">Pagina {{ indexPage + 1 }} de {{ numPages }}</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"
import { formatDate } from "../../../helpers/formatDate"
import { numberWithCommas } from '@/helpers/money';


export default {
    name: "PrintAccountsReceivableStatement",
    props: ['dataProps', 'dataCustomer', 'bussinessInfo'],
    components: { WindowPortal },
    data() {
        return {
            header: null,
            footer: null,
            loading: false,
            //seal: "",
            //sealBranch: "",
            numPages: 0,

            //valuesRows: []
            mailLabel: null,
            valuesRows: [],
            urlQr: null,
            typePrint: 0,
            myuser: JSON.parse(localStorage.getItem("user")),
            businessData: {
                address: '',
                phone: ''
            },
            customer: '',
            Currency: JSON.parse(localStorage.getItem('branch')).Currency || "",
            showLogo: '',
            total: {
                TotalNew: null,
                TotalOld: null,
                TotalPayDue: null,
                TotalAll: null,
            }
        }
    },
    mounted() {
        this.calculatePagesOfDoc()
        this.getBusinessList()
        this.getFinalTotal();
        this.getImage()
    },
    computed: {
        pages() {
            // Generar un arreglo con `numPages` elementos
            return Array.from({ length: this.numPages });
        },
    },
    watch: {
        'dataProps.show'() {
            if (this.dataProps.show) {
                setTimeout(async () => {
                    await this.calculatePagesOfDoc()
                    await this.getImage()
                }, 0);
            }
        },
        'dataProps'() {
            console.log('dataProps');
            this.calculatePagesOfDoc()
            this.getImage()
        }
    },
    methods: {


        calculatePagesOfDoc() {
            let pages = 0
            let rows = 0
            this.valuesRows[pages] = []

            try {


                this.typePrint = 0
                //Cuando es solo una página
                if (this.dataProps.data.body.length <= 30) {
                    for (let i = 0; i < this.dataProps.data.body.length; i++) {
                        rows++
                        this.valuesRows[pages].push(this.dataProps.data.body[i])
                    }
                }
                else {
                    for (let i = 0; i < this.dataProps.data.body.length; i++) {
                        if (rows < 20 && (i !== this.dataProps.data.body.length - 1)) {
                            rows++
                            this.valuesRows[pages].push(this.dataProps.data.body[i])
                        }
                        else if (rows <= 30) {
                            rows++
                            this.valuesRows[pages].push(this.dataProps.data.body[i])
                        }
                        else {
                            this.valuesRows[pages].push(this.dataProps.data.body[i])
                            pages++
                            rows = 0
                            this.valuesRows[pages] = []
                        }
                    }
                }


            }
            catch (err) {
                alert(err)
            }
            pages = pages + 1

            this.numPages = pages
        },

        async Print() {
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function (doc) {
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas: {
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            })


            //doc.deletePage(2)

            doc.autoPrint({ variant: 'non-conform' });
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        },

        async Download() {
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function (doc) {
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas: {
                    scale: 0.612,
                    //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            });

            //doc.deletePage(2)
            doc.save(this.dataProps.header + ".pdf"); //Agregado por Juan 13082023

            this.loading = false
        },
        async sendMail() {
            try {
                this.mailLabel = null;
                var doc = new jsPDF('p', 'pt', 'letter')
                //console.log(doc);
                //console.log(this.$refs.dataprint);
                await doc.html(this.$refs.dataprint, {
                    callback: function (doc) {
                        doc.output()
                    },
                    x: 0,
                    y: 0,
                    margin: [0, 0, 0, 0],
                    html2canvas: {
                        scale: 0.612,
                        //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
                        useCORS: true,
                        logging: false,
                        allowTaint: true
                    },
                });

                const body = {
                    dtefilebuffer: new Buffer.from(doc.output('arraybuffer')),
                    dtejson: this.dataProps.data.dteJson,
                    dtefilebufferfilename: this.dataProps.data.header.ID_Invoice + "_" + this.dataProps.data.header.InvoiceDate.replaceAll("/", "-") + "_" + this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"', '') + ".pdf"
                };

                // console.log(body);
                this.$API.invoices.sendInvoiceMail(body);

                this.mailLabel = 'Se envió el correo correctamente';
                this.$emit('mailSent');
            } catch (error) {
                this.mailLabel = 'Ocurrió un error al enviar el correo';
                console.log(error);
            }
        },

        async getImage() {
            console.log("DATA NEG----")
            console.log(this.bussinessInfo)
            console.log(this.bussinessInfo[0])
            const img = this.bussinessInfo[0].BusinessLogoPath
            const { imageBase64: logoImageBase64 } = await this.$API.utilities.getImageInBase64({ "file": img })
            this.showLogo = `${logoImageBase64}`
        },

        getBusinessList() {
            this.$API.business.businessListHistory().then((response) => {
                response.map((business) => {
                    if (business.ID_Business == this.myuser.businessid && business.branch == this.myuser.ID_Branch) {
                        this.businessData.address = business.BusinessAddress;
                        this.businessData.phone = business.BusinessPhone;
                    }
                }); // Store unfiltered data
            });
        },

        handleNumber(value) {
            return value ? numberWithCommas(value) : 0.00;
        },

        getFinalTotal() {
            const invoices = this.dataProps.data.body
            // Total < 15
            this.total.TotalNew = invoices.reduce((accumulator, { Minor15 }) => {
                if (!isNaN(Minor15) && Minor15 != undefined) {
                    return accumulator + parseFloat(Minor15);
                }
                return accumulator;
            }, 0)
            // Total > 15
            this.total.TotalOld = invoices.reduce((accumulator, { Major15 }) => {
                if (!isNaN(Major15) && Major15 != undefined) {
                    return accumulator + parseFloat(Major15);
                }
                return accumulator;
            }, 0)
            // Total due
            this.total.TotalPayDue = invoices.reduce((accumulator, { ExpiredBalance }) => {
                if (!isNaN(ExpiredBalance) && ExpiredBalance != undefined) {
                    return accumulator + parseFloat(ExpiredBalance);
                }
                return accumulator;
            }, 0)
            // Total all
            this.total.TotalAll = parseFloat(this.total.TotalPayDue ) + parseFloat(this.total.TotalNew) + parseFloat(this.total.TotalOld)
            //Format
            this.total.TotalNewShow = `${this.Currency} ${this.handleNumber(this.total.TotalNew)}`,
            this.total.TotalOldShow = `${this.Currency} ${this.handleNumber(this.total.TotalOld)}`,
            this.total.TotalPayDueShow = `${this.Currency} ${this.handleNumber(this.total.TotalPayDue)}`,
            this.total.TotalAllShow = `${this.Currency} ${this.handleNumber(this.total.TotalAll)}`,
            console.log("totales",this.total)
        },

        getCurrentDate() {
            const date = new Date();
            return formatDate(date);
        },
        
        fixDateFormat(date){
            return formatDate(date);
        },

        async downLoadJSON() {
            try {
                const aElement = document.createElement('a')
                aElement.setAttribute('download', this.dataProps.data.header.ID_Invoice + "_" + this.dataProps.data.header.InvoiceDate.replaceAll("/", "-") + "_" + this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"', '') + ".pdf");
                const blob = new Blob([JSON.stringify(this.dataProps.data.dteJson)], { type: 'application/json' });
                const href = URL.createObjectURL(blob);
                aElement.href = href;
                aElement.download = this.dataProps.data.header.ID_Invoice + "_" + this.dataProps.data.header.InvoiceDate.replaceAll("/", "-") + "_" + this.dataProps.data.header.InvoiceName.replaceAll(/[:/<>*|/]/g, "").replaceAll('\\"', '') + ".json";
                aElement.click();
                URL.revokeObjectURL(href);
            } catch (error) {
                this.mailLabel = 'Ocurrió un error al enviar el correo';
                console.log(error);
            }
        }
    }
}
</script>

<style scoped>
.u-row .u-col-100 {
    width: 100% !important;
}

.btnclean {
    border-radius: 15px;
    border: 1px solid white;
    background-color: #F29D35 !important;
    color: white !important;
}

.btnclose {
    border-radius: 15px;
    border: 1px solid white;
    background-color: rgb(255, 38, 38) !important;
    color: white !important;

}


* {
    font-family: Arial, Helvetica, sans-serif !important;
}
</style>
