import { render, staticRenderFns } from "./SellersAccountsReceivable.vue?vue&type=template&id=680a0452&scoped=true"
import script from "./SellersAccountsReceivable.vue?vue&type=script&lang=js"
export * from "./SellersAccountsReceivable.vue?vue&type=script&lang=js"
import style0 from "./SellersAccountsReceivable.vue?vue&type=style&index=0&id=680a0452&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "680a0452",
  null
  
)

export default component.exports