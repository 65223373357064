<template>
    <div class="white pa-4">
        <Block v-if="loading" />
        
        <v-row class="mt-2">
            <v-col cols="12" md="6">
                <v-autocomplete
                    v-model="seller"
                    :items="SellerList"
                    label="Vendedor"
                    item-text="name"
                    item-value="value"
                    outlined
                    dense
                    attach 
                    @change="getData"
                />
            </v-col>
            <v-col cols="12" md="6" class="d-flex justify-end">
                <v-btn class="btn-add" @click="setupPrint">
                    <v-icon>mdi-printer</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <VersatileFilter :columnsSearch="columsConfig" :search="search" @emptyFilter="getData" 
            @getStart=" (date) => {changueStr(date)}" @getEnd=" (date) => {changueEnd(date)}" 
                 @filtered="filterHandler"
                        :key="Reloadfilter" :columnfilterEndpoint="$API.inventoryReports.getSpecialExpiredPaymentsColReport"
                       @enabledate="(e) => {useDate = e;}"
                >
            <DxDataGrid 
            :dataSource="DataTable"
            :columns="columnsList"
            @exporting="onExporting"
            :word-wrap-enabled="true"
            :scrolling="{ useNative: true }"    
                    :column-auto-width="true"
            >
                <DxExport :enabled="true" />

                <DxPager :showPageSizeSelector="true" :allowedPageSizes="[10, 30, 50]" />
                <DxPaging :page-size="10" />
            </DxDataGrid>
        </VersatileFilter>

        <alerts
            v-if="alert.show"
            v-on:close_alert="closeAlert"
            v-on:accept_alert="acceptAlert"
            :properties="alert"
        />
        <div>
            <component v-for="(item, index) in PrintList" :key="index" :is="item.component" v-bind="item.props" />
        </div>
    </div>
</template>

<script>
    
    //import {mapState} from 'vuex';
    import VersatileFilter from '@/components/VersatileFilter.vue';
    import { DxDataGrid, DxPager, DxPaging, DxExport } from 'devextreme-vue/data-grid';
    import Block from '@/components/Block.vue';
    import Alerts from '@/components/Alerts.vue';
    import { printToExcel } from "@/helpers/printToexcel";
    import PrintSpecialExpiredPayments from "@/components/ReportsAndPrintings/SalesReports/PrintSpecialExpiredPayments";
    export default {
        name: "SpecialExpiredPayments",
        components: {
            DxDataGrid,
            DxPager,
            DxPaging,
            Block,
            Alerts,
            DxExport,
            VersatileFilter,
         PrintSpecialExpiredPayments
        },
        props: ['win', 'Sellers','business'],
        data() {
            return {
                DataTable: [],
                panel: 0,
                tab: null,
                key: 0,
                loading: false,
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                startDate: null,
                endDate: null,
                useDate : false,
                SellerList: [],
                seller: null,
                columnsList: [
                    { dataField: "NumAbono", caption: "Número de abono tardío"},
                    { dataField: "CodigoGeneracion", caption: "Código Generación" },
                    { dataField: "InvoiceDate", caption: "Fecha Factura" },
                    { dataField: "PaymentDate", caption: "Fecha de pago" },
                    { dataField: "ExpirationDate", caption: "Fecha de Vencimiento" },
                    { dataField: "DaysExpiredAfterGrace", caption: "Dias de Retraso" },
                    { dataField: "LegalName", caption: "Nombre de Cliente" },
                    { dataField: "Payment", caption: "Pago Total" },
                ],
                columsConfig: [
                    { name : "NumAbono", title: "ID Cliente", value: "",  type: "text" },
                    { name : "CodigoGeneracion", title: "Código Generación", value: "",  type: "text" },
                    { name : "InvoiceDate", title: "Fecha Factura", value: "",  type: "text" },
                    { name : "PaymentDate", title: "Fecha de pago", value: "",  type: "text" },
                    { name : "ExpirationDate", title: "Fecha de Vencimiento", value: "",  type: "text" },
                    { name : "DaysExpiredAfterGrace", title: "Dias de Retraso", value: "",  type: "text" },
                    { name : "LegalName", title: "Nombre de Cliente", value: "",  type: "text" },
                    { name : "Payment", title: "Pago Total", value: "",  type: "text" },
                ],
                Currency: JSON.parse(localStorage.getItem('branch')).Currency || "",
                search: {
                    ID_Seller: null,
                },
                summary: {
                    Total: 0,
                },
                Reloadfilter: 0,
                PrintList: [],
                print: {
                    show: false,
                    header: {
                    },
                    data: [],
                },
            }
        },
        computed:{
            
        },
        mounted() {
            this.loadData();
        },
        methods: {
            handleShowData(data){
              this.summary.Total = 0;
              this.DataTable = data.map(item => {
                    this.summary.Total += item.Payment;
                  return {
                      ...item,
                        TotalPayment: this.Currency + " " + item.TotalPayment.toFixed(2),
                        Payment: this.Currency + " " + item.Payment.toFixed(2),
                  }
              });
            },
            loadData(){
                if(this.Sellers.length > 0){
                    this.SellerList.push({name: "Sin Vendedor Asignado", value: 'ALL'});
                    this.SellerList.push(...this.Sellers);
                    this.seller = this.SellerList[0].value;
                    this.search.ID_Seller = this.seller;
                    this.getData();
                }
            },
            setupPrint(){
                this.print.data.body = this.DataTable;
                this.print.header.title = "Abonos Expirados por Vendedor ";
                const seller = this.SellerList.find(seller => seller.value === this.seller);
                const date = new Date();
                if(seller.value =='ALL'){
                    this.print.header.seller = "Sin vendendor asignado";
                }
                else{
                    this.print.header.seller =  seller.name;
                }
                this.print.header.summary = this.summary;
                this.print.header.business = this.business;
                //date wiht format dd/mm/yyyy
                this.print.header.cutDate = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

                this.print.show = true;
                 this.PrintList.push({component: PrintSpecialExpiredPayments, props: {
                    dataProps: this.print,
                }}); 
            },
            onExporting(e) {
              const sellerdata = this.Sellers.find(seller => seller.value === this.seller); 
              printToExcel(e, "Reporte de De Abonos Expirados "+ sellerdata.name ? sellerdata.name : "");
            },
            filterHandler(data){
            
                if(data.length > 0){
                    this.handleShowData(data);
                    return;
                }
                else{
                    this.DataTable = [];
                }
                
            },
            async getData(){
                this.loading = true;
                this.search.ID_Seller = this.seller;
                this.Reloadfilter++;
                let dates = null;
                if(this.useDate){
                    dates = {
                        startDate: this.startDate,
                        endDate: this.endDate,
                    }
                }
               
                try {
                    let response = await this.$API.inventoryReports.getSpecialExpiredPayments({id_seller: this.seller, dates});
                    if(response.length > 0){
                        this.handleShowData(response);
                    }
                    else{
                        this.DataTable = [];
                    }
                    
                    this.loading = false;    
                } catch (error) {
                    console.log(error);
                    this.loading = false;
                    this.showAlert('danger', 'Error', "Error al obtener los datos");
                }
                this.loading = false;
            },
            closeAlert() {
                this.alert.show = false; 
            },
            acceptAlert() {
                this.alert.show = false;
            },
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },
            changueStr(date){
                this.startDate = date;
            },
            changueEnd(date){
                this.endDate = date;
            },
           
        },

    }
</script>

<style scoped>
.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}
.btn-add{
  background-color: #3498DB  !important;
  color: white;
}
.btnsave{
  background-color: #00A178 !important;
  color: white;
}
.btnclean{
  background-color: #F29D35 !important;
  color: white;
}
.btndelete{
  background-color: #E3191E !important;
  color: white;
}


</style>