<template>
    <div class="white pa-4 ">
        <v-row class="my-2 mr-3">
            <v-col cols="12" md="4">
                <date-picker label="Facturas expiradas a la fecha de:" @dateSelected="changeDate" />
            </v-col>
            <v-col cols="12" md="2">
                <v-text-field
                  v-model="daysOfDue"
                  outlined dense
                  attach
                  type="number"
                  label="Días de vencimiento"
                  :rules="rules"
                  @input="onlyDigitsEvent"
                ></v-text-field>
                <!-- <v-autocomplete v-model="daysOfDue" outlined dense attach label="Días de vencimiento" :items="daysOfDueCollection" item-text="name"
                    item-value="value"></v-autocomplete> -->
            </v-col>
            <v-col cols="12" md="1">
                
            </v-col>
            <v-col cols="12" md="4">

            </v-col>
            <v-col cols="12" md="1" class="d-flex justify-end">
                <v-btn class="btn-add" @click="printReport" small>
                    <v-icon>mdi-printer</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row class="my-2 mr-3">
            <v-col cols="12" md="4">
                <v-autocomplete v-model="id_seller" outlined dense attach label="Vendedor" :items="ExpiredAccountsSellerList" item-text="name"
                    item-value="value"></v-autocomplete>
            </v-col>
            <v-col cols="12" md="2" class="d-flex justify-center">
                <v-btn class="btn-add mx-2" @click="getExpiredAccountsReceivable">Filtrar<v-icon>mdi-filter</v-icon></v-btn>
            </v-col>
        </v-row>
        <block v-if="loading"></block>
        <div>
            <!-- <GeneralFilter class="backgroundGlobal border-boxGlobal" :search="{}" :filterEndpoint="$API.customers.getExpiredAccountsReceivableFiltered" @emptyFilter="getExpiredAccountsReceivable" @filtered="filterHandler" > -->
                <DxDataGrid class="" :data-source="expiredAccountReceivables" @exporting="onExporting" :scrolling="{ useNative: true }">
                    <DxExport :enabled="true" />
                    <DxPaging :page-size="10" />
                <!--     <DxColumn data-field="CodigoGeneracion" caption="Codigo de Generacion"></DxColumn>
                    <DxColumn data-field="ID_Invoice" caption="ID Interno de la factura"></DxColumn> -->
                    <DxColumn :width="columnWidth" data-field="LegalName" caption="Razón Social"></DxColumn>
                    <DxColumn :width="columnWidth" data-field="SellerAssigned" caption="Vendedor Responsable"></DxColumn>
                    <DxColumn :width="columnWidth" data-field="NotExpiredBalanceFormatted" caption="Saldo sin Vencimiento"></DxColumn>
                    <DxColumn :width="columnWidth" data-field="ExpiredBalanceFormatted" caption="Saldo Vencido"></DxColumn>
                    <DxColumn :width="columnWidth" data-field="TotalFormatted" caption="Total"></DxColumn>
                </DxDataGrid>
            <!-- </GeneralFilter> -->
            <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
            </alerts>
        </div>
        <div>
            <ExpiredAccountsReceivableReport :dataProps="print"  />
        </div>
    </div>
</template>

<script>
import { DxDataGrid, DxColumn, DxPaging, DxExport } from 'devextreme-vue/data-grid'
import DatePicker from '@/components/DatePicker'
import { printToExcel } from "@/helpers/printToexcel"
import Block from "@/components/Block"
import ExpiredAccountsReceivableReport from '@/components/ReportsAndPrintings/ExpiredAccountsReceivableReport'
import { numberWithCommas } from "@/helpers/money";
import { convertDateFormat } from '@/helpers/formatDate';
import { onlyDigits } from '../../../helpers/masks'
import Alerts from '@/components/Alerts.vue'

export default {
    props: {
        reportModule :{
        type: Boolean,
        default: false
        },
        isCarsem: {
            type: Boolean,
            default: false
        }
    },
    name: "ExpiredAccountsReceivable",
    components: {
        DxColumn,
        DxDataGrid,
        //GeneralFilter,
        DxPaging,
        Block,
        DxExport,
        Alerts,
        ExpiredAccountsReceivableReport,
        DatePicker,
        // CustomAutoComplete, SearchDialog
    },
    data() {
        return {
            rules: [(v) => !!v || "Este campo es requerido"],
            daysOfDue: 30,
            ID_Seller: null,
            sellersList: [],
            loading: false,
            expiredAccountReceivables: [],
            Currency: JSON.parse(localStorage.getItem('branch')).Currency,
            printList: [],
            requestDate: null,
            print: {
                show: false,
                header: {},
                data: {
                    body: []
                },
                footer: {}
            
            },
            targetFormat: "yyyy-mm-dd",
            alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
            },
            ExpiredAccountsSellerList: [],
            id_seller: null
        }
    },
    async mounted() {
        const date = new Date()
        this.requestDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate()}`;
        await this.loadSellers();
        this.getExpiredAccountsReceivable()
    },
    methods: {
        async loadSellers(){
            try {
                const { data: response } = await this.$API.customers.getExpiredAccountsReceivableSellers( this.requestDate, this.daysOfDue );
                this.ExpiredAccountsSellerList.push({name: "Sin Vendedor Asignado", value: 'ALL'});
                this.ExpiredAccountsSellerList.push(...response);
                this.id_seller = this.ExpiredAccountsSellerList[0].value;
            } catch (error) {
                this.showAlert( 'error', 'Error', 'No fue posible cargar los datos del vendedor' );
            }
        },
        numberWithCommas( value ) {
            return `${ this.Currency }${ numberWithCommas( value ) }`;
        },
        changeDate( param ) {
            this.requestDate = convertDateFormat( param, null, this.targetFormat );
        },
        printReport() {
            this.print.show = true;
            this.print.data.body = this.expiredAccountReceivables;
            this.print.header.title = "Cuentas Vencidas a 30 Días";
            const date = new Date();
            this.print.header.date = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
            if (this.requestDate) {
                const [year, month, day] = this.requestDate.split('-');
                this.print.header.dateperiod = `${parseInt(day)}/${parseInt(month)}/${year}`;
            } else {
                this.print.header.dateperiod = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
            }
            
        },
        onExporting(e) {
            printToExcel(e, "Cuentas Por Cobrar Vencidas")
        },
        async onlyDigitsEvent(e) {
            this.daysOfDue = await onlyDigits( this, e);
        },
        getExpiredAccountsReceivable() {
            this.loading = true
            if( new Date( this.requestDate ) && this.daysOfDue > 0 ) {
                this.$API.customers.getExpiredAccountsReceivable( this.requestDate, this.daysOfDue, this.id_seller )
                    .then(response => {
                        const { data } = response
                        // console.log( 'response ', data );
                        // this.setupData( dataDetallada );
                        this.expiredAccountReceivables = data;
                        this.loading = false
                    });

            } else {
                console.log( 'nel' );
                this.showAlert( 'warning', 'Error', 'Debe llenar los datos requeridos para el reporte' );
                this.loading = false
            }
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
            console.log( 'aca', this.alert );
        },
        acceptAlert() {
            this.alert.show = false;
        },
        closeAlert(){
            this.alert.show = false;
        },
        filterHandler(response) {
            if(this.reportModule){
                this.expiredAccountReceivables = this.setupData(response)
            }
            else{
                this.expiredAccountReceivables = response
            }
        },
        // dateFilterHandler(filteredData) {
        //     // if(this.reportModule){
        //     //     this.expiredAccountReceivables = this.setupData(filteredData)
        //     // }
        //     // else{
        //     // }
        //     this.expiredAccountReceivables = filteredData
        // },
    },
    computed:{
            columnWidth() {
    
    return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
  },
        },
}

</script>

<style scoped>
.btn-add {
  background-color: #3498db !important;
  color: white;
}


.item{
    @media screen and (min-width: 1068px) {
        position: absolute;
        z-index: 1;
    }
    @media screen and (max-width: 900px) {
        padding-bottom: 20px;
    }
}
</style>