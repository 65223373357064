<template>
    <div class="white pa-4">
        <Block v-if="loading" />
        <v-card flat class="pa-4 bar">
            <div class="d-flex justify-end align-center">
                <v-btn
                    class="btn-add mr-1" small
                    @click.prevent="setupPrint"
                >
                    <v-icon>
                        mdi-printer
                    </v-icon>
                   
                </v-btn>
            </div>
        </v-card>

        <v-row style="margin: 1rem 0">
            <v-col cols="12">
                <v-radio-group v-model="dataType">
                    <v-radio label="Ver reporte con datos de prueba" value="00"></v-radio>
                    <v-radio label="Ver reporte con datos de producción" value="01"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>

        <div class="mt-2 ">
            <p><b>Nota: </b>Por defecto, se toma la información de los vendedores de los últimos 30 días de forma predeterminada.</p>
        </div>
        <VersatileFilter :columnsSearch="columsConfig"  :search="search" @emptyFilter="getSummaryAccountsSellers" @filtered="filterHandler"
        :key="Reloadfilter" :columnfilterEndpoint="$API.customers.getsSummaryAccountsSellersBycolumn" @getStart=" (date) => {changueStr(date)}" @getEnd=" (date) => {changueEnd(date)}"  
      >
            <DxDataGrid 
            :dataSource="DataTable"
            :columns="columnsList"
            :scrolling="{ useNative: true }"    
                    :column-auto-width="true"
                    width="100%"
            >
                <DxPager :showPageSizeSelector="true" :allowedPageSizes="[5, 10, 20]" />
                <DxPaging :defaultPageSize="5" />
            </DxDataGrid>
        </VersatileFilter>
        <v-col cols="12" md="12" class="d-flex justify-center align-center">
            <h1>
               Totales
            </h1>
        </v-col>
        <v-row class="d-flex justify-center align-center">
            <v-col class="d-flex justify-center align-center" cols="12" xxl="4" xl="4" lg="4" md="4" sm="4">
                <v-card-title>
                    Contado: {{Currency}} {{ handleNumber(summary.Contado.toFixed(2)) }}
                </v-card-title>
            </v-col>
            <v-col class="d-flex justify-center align-center" cols="12" xxl="4" xl="4" lg="4" md="4" sm="4">
                <v-card-title>
                    Crédito: {{Currency}} {{ handleNumber(summary.Credito.toFixed(2)) }}
                </v-card-title>
            </v-col>
            <v-col class="d-flex justify-center align-center" cols="12" xxl="4" xl="4" lg="4" md="4" sm="4">
                <v-card-title>
                    Total: {{Currency}} {{ handleNumber(summary.Total.toFixed(2)) }}
                </v-card-title>
            </v-col>
        </v-row>
        <div>
            <component v-for="win in windowsList" :is="win.component" :key="win.key" :dataProps="win.props" />

        </div>
        <alerts
            v-if="alert.show"
            v-on:close_alert="closeAlert"
            v-on:accept_alert="acceptAlert"
            :properties="alert"
        />
    </div>
</template>

<script>
    import {DxDataGrid, DxPager, DxPaging} from 'devextreme-vue/data-grid';
    import Block from '@/components/Block.vue';
    import Alerts from '@/components/Alerts.vue';
    import VersatileFilter from '@/components/VersatileFilter.vue';
    import { numberWithCommas } from "@/helpers/money";
    import PrintSummaryAccountsSellers from "@/components/ReportsAndPrintings/SalesReports/PrintSummaryAccountsSellers";
    export default {
        name: "SummaryAccountsSellers",
        components: {
            DxDataGrid,
            DxPager,
            DxPaging,
            Block,
            Alerts,
            VersatileFilter
        },
        props: ['win','business'],
        data() {
            return {
                dataType: '01',
                DataTable: [],
                panel: 0,
                print: {
                    show: false,
                    data: {},
                    header: {
                        business: {},
                        title: "",
                        cutDate: ""
                    }
                },
                windowsList: [],
                tab: null,
                key: 0,
                loading: false,
                alert: {
                    type: "success",
                    show: false,
                    header: "",
                    body: "",
                },
                search: {

                },
                columsConfig:[
                { name : "ID_Seller", title: "ID Vendedor", value: "",  type: "text" },
                { name : "SellerName", title: "Vendedor", value: "",  type: "text" },
               
                ],
                columnsList: [
                    { dataField: "ID_Seller", caption: "ID Vendedor", width:this.columnWidth },
                    { dataField: "SellerName", caption: "Vendedor", width:this.columnWidth },
                    { dataField : "Abono", caption: "Abono", width:this.columnWidth },
                    { dataField : "Contado", caption: "Contado", width:this.columnWidth },
                    { dataField : "Credito", caption: "Crédito", width:this.columnWidth },
                   { dataField : "Total", caption: "Total", width:this.columnWidth },
                ],
                start: null,
                end: null,
                usingdefault: false,
                Currency: JSON.parse(localStorage.getItem('branch')).Currency,
                summary: {
                    Contado: 0,
                    Abono: 0,
                    Total: 0
                },
                
                Reloadfilter: 0
                
            }
        },
        computed:{
            isMobile() {
                return this.$vuetify.breakpoint.smAndDown;
            },
            columnWidth() {
    
                return this.$vuetify.breakpoint.smAndDown ? '150px' : 'auto !important';
            },
        },
        watch: {
            dataType(newVal) {
                this.search.dataType = newVal;
            },
        },
        mounted(){
            this.search.dataType = this.dataType;
            this.getSummaryAccountsSellers();
            console.log(this.search);
        },
        methods: {
            handleNumber(value){
                return value ? numberWithCommas(value) : 0.00;
            },
            calculateValues(data){
                this.summary = {
                    Contado: 0,
                    Credito: 0,
                    Total: 0
                }
                if(data.length > 0){
                    this.summary.Contado = data.reduce((acc, item) => acc + item.Contado, 0);
                    this.summary.Credito = data.reduce((acc, item) => acc + item.Credito, 0);
                  
                    data.forEach((item) => {
                        const tot = (!isNaN(item.Contado) ?  item.Contado : 0) + (!isNaN(item.Credito) ? item.Credito : 0);
                        try {
                            item.Contado = this.Currency + ' ' +numberWithCommas(item.Contado ? item.Contado.toFixed(2) : 0);
                            item.Abono = this.Currency + ' ' +numberWithCommas(item.Abono ? item.Abono.toFixed(2) : 0);
                            item.Credito = this.Currency + ' ' +numberWithCommas(item.Credito ? item.Credito.toFixed(2) : 0);
                            this.summary.Total += tot;
                            item.Total = this.Currency + ' ' + numberWithCommas(tot.toFixed(2));
                            
                        } catch (error) {
                            console.log( error );
                        }
                    });
                    return data;
                }
                return [];
            },
            filterHandler(data){
                this.usingdefault = data.usingdefault;
                this.DataTable  = this.calculateValues(data.data);
            },
            changueStr(date){
                this.start = date;
            },
            changueEnd(date){
                this.end = date;
            },
            closeAlert() {
                this.alert.show = false; 
            },
            acceptAlert() {
                this.alert.show = false;
            },
            showAlert(type, header, body, options = null) {
                type = type == null ? "danger" : type;
                this.alert.type = type;
                this.alert.header = header;
                this.alert.body = body;
                this.alert.show = true;
                this.alert.options = options != null ? options : null;
            },
            setupPrint(){
                this.print.show = true;
                this.print.data.body = this.DataTable;
                const date = new Date();
                const currentDate = new Date();
                const thirtyDaysAgo = new Date(currentDate);
                thirtyDaysAgo.setDate(currentDate.getDate() - 30);

                this.print.header.usingdefault = this.usingdefault ? `${thirtyDaysAgo.getDate()}/${thirtyDaysAgo.getMonth() + 1}/${thirtyDaysAgo.getFullYear()} - ${currentDate.getDate()}/${currentDate.getMonth() + 1}/${currentDate.getFullYear()}` : 
                `${this.start} - ${this.end} `;
            
                this.print.header.business = this.business;
                this.print.header.title = "Reporte de Movimientos";
                this.print.data.summary = this.summary;
                //day format dd/mm//yyyy
                this.print.header.cutDate =  date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
                
                this.windowsList.push({component: PrintSummaryAccountsSellers, key: this.windowsList.length, props: this.print});
            },
            getSummaryAccountsSellers(){
                this.loading = true;
                const dates = {
                    start: this.start,
                    end: this.end
                }
                this.$API.customers.getsSummaryAccountsSellers({ dates , dataType: this.dataType }).then((res) => {
                    this.DataTable =  this.calculateValues(res.data);
                    this.usingdefault = res.usingdefault;
                    this.loading = false;
                }).catch((err) => {
                    console.log(err);
                    this.loading = false;
                    this.showAlert("danger", "Error", "Error al obtener los datos");
                });
            },
           
        },

    }
</script>

<style scoped>
.bar {
  width: 100%;
  background-color: rgb(60, 60, 60) !important;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-radius: 15px !important;
  margin-top: 1rem !important;
}
.btn-add{
  background-color: #3498DB  !important;
  color: white;
}
.btnsave{
  background-color: #00A178 !important;
  color: white;
}
.btnclean{
  background-color: #F29D35 !important;
  color: white;
}
.btndelete{
  background-color: #E3191E !important;
  color: white;
}


</style>