<template>
    <div class="white pa-4">
        <Block v-if="loading" />

        <v-row class="mt-2">
            <v-col cols="12" md="6">
                <v-autocomplete v-model="customer" :items="ClientList" label="Cliente" item-text="name"
                    item-value="value" outlined dense attach @change="getData()" />
            </v-col>
            <v-col cols="12" md="6" class="d-flex justify-end">
                <v-btn class="btn-add" @click="setupPrint">
                    <v-icon>mdi-printer</v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-radio-group v-model="envType">
                    <v-radio label="Ver reporte con datos de prueba" value="00"></v-radio>
                    <v-radio label="Ver reporte con datos de producción" value="01"></v-radio>
                </v-radio-group>
            </v-col>
        </v-row>
        <VersatileFilter :columnsSearch="columsConfig" :search="search" @emptyFilter="getData" :disableDates="true"
            @filtered="filterHandler" :key="Reloadfilter"
            :columnfilterEndpoint="$API.customers.getCustomerAccountStatusBycolumn">
            <DxDataGrid :dataSource="DataTable" :columns="columnsList" @exporting="onExporting"
                :word-wrap-enabled="true" :scrolling="{ useNative: true }" :column-auto-width="true">
                <DxExport :enabled="true" />

                <DxPager :showPageSizeSelector="true" :allowedPageSizes="[10, 30, 50]" />
                <DxPaging :page-size="10" />
            </DxDataGrid>
        </VersatileFilter>

        <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert" />
        <div>
            <component v-for="(item, index) in PrintList" :key="index" :is="item.component" v-bind="item.props" />
        </div>
    </div>
</template>

<script>

//import {mapState} from 'vuex';
import VersatileFilter from '@/components/VersatileFilter.vue';
import { DxDataGrid, DxPager, DxPaging, DxExport } from 'devextreme-vue/data-grid';
import Block from '@/components/Block.vue';
import Alerts from '@/components/Alerts.vue';
import { printToExcel } from "@/helpers/printToexcel";
import PrintAccountsReceivableStatement from "@/components/ReportsAndPrintings/SalesReports/PrintAccountsReceivableStatement"
import { numberWithCommas } from '@/helpers/money';
export default {
    name: "SellersAccountsReceivable",
    components: {
        DxDataGrid,
        DxPager,
        DxPaging,
        Block,
        Alerts,
        DxExport,
        VersatileFilter,
        PrintAccountsReceivableStatement
    },
    props: ['win', 'Customers'],
    data() {
        return {
            PrintList: [],
            print: {
                show: false,
                header: {
                },
                data: [],
            },
            DataTable: [],
            panel: 0,
            tab: null,
            key: 0,
            loading: false,
            alert: {
                type: "success",
                show: false,
                header: "",
                body: "",
            },
            ClientList: [],
            customer: null,
            envType: '01',
            columnsList: [
                { dataField: "ID_Invoice", caption: "No. Documento" },
                { dataField: "FormattedInvoiceDate", caption: "Fecha" },
                { dataField: "FormattedExpirationDate", caption: "Vencimiento" },
                { dataField: "Minor15Formatted", caption: "Menor a 15 Días" },
                { dataField: "Major15Formatted", caption: "Mayor a 15 Días" },
                { dataField: "ExpiredBalanceFormatted", caption: "Saldo Vencido" },
            ],
            columsConfig: [
                { name: "c.ID_Customer", title: "ID Cliente", value: "", type: "text" },
                { name: "c.LegalName", title: "Nombre de Cliente", value: "", type: "text" },
                { name: "vas.ID_Invoice", title: "ID Factura", value: "", type: "text" }
            ],
            Currency: JSON.parse(localStorage.getItem('branch')).Currency || "",
            idbusiness: JSON.parse(localStorage.getItem("user")).businessid || "",
            idbranch: JSON.parse(localStorage.getItem("user")).branch || "",
            search: {
                ID_Customer: null,
            },
            Reloadfilter: 0,
            businessAndBranchInfo: []
        }
    },
    watch: {
        envType() {
            this.getData();
        }
    },  
    computed: {

    },
    mounted() {
        this.loadData();
        this.getInfo()
    },
    methods: {
        async getInfo() {
            this.$API.branches.findSpecificBranch({ "branch": this.idbranch, "business": this.idbusiness }).then((response) => {
                this.businessAndBranchInfo = response
            })
        },

        loadData() {
            if (this.Customers.length > 0) {
                this.ClientList.push({ name: "Sin Cliente Asignado", value: 'ALL' });
                this.ClientList.push(...this.Customers);
                this.customer = this.ClientList[0].value;
                this.search.ID_Customer = this.customer;
                this.getData();
            }
        },
        onExporting(e) {
            const customerdata = this.Customers.find(customer => customer.value === this.customer);
            printToExcel(e, "Reporte de cobros Por Vendedor " + customerdata.name);
        },
        filterHandler(data) {
            if (data.length > 0) {
                this.DataTable = data.map(item => {
                    return item
                });
                return;
            }
            this.DataTable = [];

        },
        setupPrint() {
            this.print.data.body = this.DataTable;
            this.print.header.title = "Reporte de Cuentas por Cobrar por Vendedor ";
            const customer = this.ClientList.find(customer => customer.value === this.customer);
            if (customer.value == 'ALL') {
                this.print.header.subtitle = "Sin vendendor asignado";
            }
            else {
                this.print.header.subtitle = "ID : " + customer.value + " - " + customer.name;
            }
            this.print.show = true;
            this.PrintList.push({
                component: PrintAccountsReceivableStatement, props: {
                    dataProps: this.print,
                    dataCustomer: customer,
                    bussinessInfo: this.businessAndBranchInfo
                }
            });
        },
        async getData() {
            this.loading = true;
            this.search.ID_Customer = this.customer;
            this.Reloadfilter++;

            try {
                let response = await this.$API.customers.getCustomerAccountStatus( this.customer, this.envType );
                if (response.length > 0) {
                    this.DataTable = response
                    this.formatTable()
                }
                else {
                    this.DataTable = [];
                }

            } catch (error) {
                this.showAlert('danger', 'Error', error.response.data.message);
            }
            this.loading = false;
        },
        closeAlert() {
            this.alert.show = false;
        },
        acceptAlert() {
            this.alert.show = false;
        },
        handleNumber(value) {
            return value ? numberWithCommas(value) : 0.00;
        },
        formatTable() {
            this.DataTable.map((invoice) => {
                console.log(invoice.IsInvoiceExpired, invoice.TotalDebt)
                if (invoice.IsInvoiceExpired == 'Mayor 15 días') {
                    invoice.InvoiceCreditOldCalc = invoice.TotalDebt
                    invoice.InvoiceCreditOld = `${this.Currency} ${this.handleNumber(invoice.TotalDebt)}`
                } else if (invoice.IsInvoiceExpired == 'Menor 15 días') {
                    invoice.InvoiceCreditNewCalc = invoice.TotalDebt
                    invoice.InvoiceCreditNew = `${this.Currency} ${this.handleNumber(invoice.TotalDebt)}`
                } else if (invoice.IsInvoiceExpired == 'Vencida') {
                    invoice.PendingToPayDueCalc = invoice.TotalDebt
                    invoice.PendingToPayDue = `${this.Currency} ${this.handleNumber(invoice.TotalDebt)}`
                }
            })
        },
        showAlert(type, header, body, options = null) {
            type = type == null ? "danger" : type;
            this.alert.type = type;
            this.alert.header = header;
            this.alert.body = body;
            this.alert.show = true;
            this.alert.options = options != null ? options : null;
        },
    },

}
</script>

<style scoped>
.bar {
    width: 100%;
    background-color: rgb(60, 60, 60) !important;
    color: white !important;
    border-style: solid;
    border-color: black;
    border-radius: 15px !important;
    margin-top: 1rem !important;
}

.btn-add {
    background-color: #3498DB !important;
    color: white;
}

.btnsave {
    background-color: #00A178 !important;
    color: white;
}

.btnclean {
    background-color: #F29D35 !important;
    color: white;
}

.btndelete {
    background-color: #E3191E !important;
    color: white;
}
</style>